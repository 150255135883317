import Image from '@tiptap/extension-image';
import { mergeAttributes } from '@tiptap/core';

function styleStringToObject(styleString) {
    return styleString.split(';').reduce((styleObject, styleProperty) => {
        if (styleProperty.trim()) {
            let [property, value] = styleProperty.split(':');
            property = property.trim().replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
            value = value.trim();
            styleObject[property] = value;
        }
        return styleObject;
    }, {});
}

function styleObjectToString(styleObject) {
    return Object.entries(styleObject).map(([property, value]) => {
        property = property.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`);
        return `${property}: ${value};`;
    }).join(' ');
}

export const CustomImage = Image.extend({
    name: 'custom-image',
    addAttributes () {
        return {
            ...Image.config.addAttributes(),
            size: {
                default: null,
                rendered: false
            },
            float: {
                default: null,
                rendered: false
            },
            width: {
                default: null,
                rendered: false
            },
            height: {
                default: null,
                rendered: false
            },
            fit: {
                default: null,
                rendered: false
            },
            href: {
                default: null,
                rendered: false
            },
            target: {
                default: '_blank',
                rendered: false
            },
            style: {
                default: '',
                rendered: false
            }
        };
    },

    addCommands () {
        return {
            setImage: (options) => ({ tr, commands }) => {
                if (tr.selection?.node?.type?.name == 'custom-image') {
                    return commands.updateAttributes('custom-image', options);
                } else {
                    return commands.insertContent({
                        type: this.name,
                        attrs: options
                    });
                }
            }
        };
    },

    renderHTML ({ node, HTMLAttributes }) {
        let styles = {};
        if(node.attrs.style){
            styles = styleStringToObject(node.attrs.style);
        }
        if(node.attrs.float && node.attrs.float !== 'no-float'){
            styles.float = `${node.attrs.float}`;
        }
        if (node.attrs.width) {
            styles.maxWidth = `${node.attrs.width}px`;
        } else if(node.attrs.size){
            styles.maxWidth = `${node.attrs.size}px`;
        }
        if (node.attrs.height) {
            styles.height =  `${node.attrs.height}px`;
        }
        if(node.attrs.fit){
            styles.objectFit =  `${node.attrs.fit}`;
        }
        if(!styles.objectFit){
            styles.objectFit = 'fill';
        }
        if(styles.float){
            node.attrs.float = styles.float;
            styles['marginLeft'] = 'auto';
            styles['marginRight'] = 'auto';
            styles['display'] = 'block';
        }else {
            node.attrs.float = 'no-float';
            styles['marginLeft'] = '0';
            styles['marginRight'] = '0';
            styles['display'] = 'inline';
        }
        node.attrs.fit = styles.objectFit;
        if(!styles.maxWidth){
            styles.maxWidth = '200px';
        } else {
            node.attrs.width = styles.maxWidth?.replace('px','');
        }
        if(styles.height){
            node.attrs.height = styles.height?.replace('px','');
        }
        HTMLAttributes.style = styleObjectToString(styles);
        if(node.attrs.href){
            HTMLAttributes.href = node.attrs.href;
        }
        const imgElement = ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)];
        if (node.attrs.href) {
            return ['a', { href: node.attrs.href, target: node.attrs.target }, imgElement];
        }
        return imgElement;
    }
});
